import { useEffect, useState } from 'react';
import useEspial from './useEspial';

const useSendEspialPurchaseEventOnce = (
  orderItems: {
    number: number;
    productName: string;
    productNumber: string;
    productPrice: number;
  }[],
  isFirstPurchase: boolean,
) => {
  const { trackPurchase, trackHouseholdMemberAdded, trackMembershipUpgraded } = useEspial();
  const [tagIsSent, setTagIsSent] = useState(false);

  useEffect(() => {
    if (!tagIsSent && orderItems?.length > 0) {
      trackPurchase(orderItems, isFirstPurchase);

      if (!isFirstPurchase && orderItems.some((i) => i.productNumber === '2020')) {
        trackMembershipUpgraded();
      } else if (!isFirstPurchase && orderItems.some((i) => i.productNumber !== '2020')) {
        trackHouseholdMemberAdded();
      }

      setTagIsSent(true);
    }
  }, [tagIsSent, orderItems, trackPurchase, isFirstPurchase, trackHouseholdMemberAdded, trackMembershipUpgraded]);
};

export default useSendEspialPurchaseEventOnce;
